import React from "react";
import AccordionItem from "./AccordionItem";

const Menu = () => {
  return (
    <section id="menu">
      <h2 className="text-deepRed italic text-center font-bold volkhov md:text-[46px] sm:text-[35px] text-[25px] md:mt-[70px] mt-[50px] ">
        Our Menu
      </h2>

      <div className="flex items-center flex-col justify-center gap-[30px] md:mt-[100px] mt-[50px]">
        <img src="/images/menu-logo.svg" alt="menu_logo" />
        <p className="max-w-[550px] text-center text-warning sm:leading-[28px] leading-[20px] sm:text-[18px] text-[16px] mb-[38px] px-[1rem]">
          from full breakfast to a wide range of carefully selected lunch &
          dinner meal options fused with some of the popular local cuisine.
        </p>
      </div>

      <div className="flex items-center gap-[2rem]">
        <img
          src="/images/flower.svg"
          alt="flower__image"
          className="mb-[-120px] md:w-[200px] w-[150px]  sm:flex hidden"
        />

        <div className="flex lg:flex-row lg:px-0 sm:px-[2rem] flex-col items-center gap-[3rem] px-[1rem]">
          <div className="xl:ml-[100px] lg:ml-[50px] ml-0">
            <div className="flex gap-[12px] mb-[32px]">
              <img
                src="/images/breakfast-icon.svg"
                alt="icon"
                className="w-[40px] h-[44px]"
              />
              <p className="text-lightGreen text-[24px] italic volkhov mt-[10px]">
                Breakfast
              </p>
            </div>

            <div className="flex md:flex-row flex-col gap-[1rem] h-[400px] overflow-auto scroll__bar pr-[1.5rem]">
              <div>
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />

                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />

                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />

                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
              </div>
              <div>
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />

                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />

                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />

                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"American Breakfast"}
                  price={"GHS 95"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Americano"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Assorted Flavours"}
                  price={"GHS 15"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
                <AccordionItem
                  titleColor={"lightGreen"}
                  priceColor={"lightRed_100"}
                  title={"Breakfast Tea"}
                  price={"GHS 20"}
                  content={
                    "4 Fluffy pancakes, maple syrup, scrambled eggs and two bacon rashers."
                  }
                />
              </div>
            </div>
          </div>

          <img
            src="/images/menu-img.svg"
            alt=""
            className="xl:w-[600px] lg:w-[400px] sm:w-[500px] w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default Menu;
